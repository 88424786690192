import React from "react";
import {OrderContext} from '../../context/OrderContext'

const ProductCard = ({product, showQuantitySelector}) => {

    const {products, setProducts} = React.useContext(OrderContext)
    const addToCart = () => {
        setProducts(products.map((prod) => prod.id === product.id ? {...prod, quantity: product.quantity + 1} : prod))
    }
    const subsQty = () => {
        setProducts(products.map((prod) => prod.id === product.id ? {...prod, quantity: product.quantity > 0 ? product.quantity -1 : 0} : prod))
    }
    
    return (
        <div className="product-card text-center my-2" id={product.id}>
            <img src={`http://api-landing.rosmino.com.ar/${product.image}`} height="120" alt={`Rosmino Ivess - Dispenser ${product.name}`} />
            <h3 className="fs-14 text-dark-grey text-center text-regular mb-2 mt-3">{product.name}</h3>
            <p className="text-black text-center">$ {product.price}</p>
            { showQuantitySelector &&
                <div className="product-qty-counter mt-3">
                    <span className={product.quantity >= 1 ? "counter-button" : "counter-button disabled" } onClick={subsQty} disabled={product.quantity < 1 ? true : false}>-</span>
                    <p className="fs-14 text-regular px-3">{product.quantity}</p>
                    <span className="counter-button" onClick={addToCart}>+</span>
                </div>
            }
        </div>
    )
}

export default ProductCard;