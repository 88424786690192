 export const stepsValid = [
    {
        id:0,
        label:"Domicilio",
        name:"step 1", 
        invalid: true,
        // validator: addressValidation,
    },
    {
        id:1,
        label:"Dispenser",
        name:"step 2", 
        // validator: dispenserValidation,
    },
    {
        id:2,
        label:"Productos",
        name:"step 3", 
        // validator: productsValidation,
    },
    {
        id:3,
        label:"Visita",
        name:"step 4", 
        // validator: visitValidation,
    },
    {
        id:4,
        label:"Datos",
        name:"step 5", 
        // validator: personalInfoValidation,
    },
    {
        id:5,
        label:"Confirmación",
        name:"step 6", 
        // validator: automaticConfirmationValidation,
    },

]
 export const stepsInvalid = [
    {
        id:0,
        label:"Domicilio",
        name:"step 1", 
        invalid: true,
        // validator: addressValidation,
    },
    
    {
        id:1,
        label:"Datos",
        name:"step 2", 
        // validator: personalInfoValidation,
    },
    {
        id:2,
        label:"Confirmación",
        name:"step 3", 
        // validator: automaticConfirmationValidation,
    },

]