import * as React from "react";
import parse from 'html-react-parser'
import {Form} from 'react-bootstrap'
const MainDispenserRadiobutton = (props) => {
    const {title, subtitle, name, id, measures, img, setFormValues, formValues} = props;

    return (
        <div className="main-dispenser-radiobutton">
            <label htmlFor={id}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <h2 className="fs-16 text-bold m-0">{title}</h2>
                        <span className="badge ms-3">Gratis</span>
                    </div>
                    <Form.Check 
                        id={id}
                        name={name}
                        type="radio"
                        className='radiobutton' 
                        required
                        onChange={() => setFormValues({product_type: id})}
                        defaultChecked={formValues["product_type"] === id ? true : false}
                    />
                </div>
                <p className="fs-12 mt-3">{parse(subtitle)}</p>
                <div className="img-container d-flex align-items-center">
                    <img src={`http://api-landing.rosmino.com.ar/${img}`} height="175" alt={`Rosmino Ivess - Dispenser ${title}`} />
                    <div className="d-flex flex-column ms-4">
                        <p className="fs-12 text-medium text-black">Medidas</p>
                        <p className="text-regular text-dark-grey fs-12">Alto {measures.Alto}</p>
                        <p className="text-regular text-dark-grey fs-12">Ancho {measures.Ancho}</p>
                        <p className="text-regular text-dark-grey fs-12">Profundidad {measures.Profundidad}</p>
                    </div>
                </div>
            </label>
        </div>
    )
}

export default MainDispenserRadiobutton;