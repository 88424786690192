import React from 'react';


export const stepVisitData = {
    title:"¿Cuándo querés que te visitemos?",
    buttonTitle:"Necesito recibir el pedido más rápido",
}


export const stepVisitContactData = {
    title:"¿Cómo querés que te contactemos?",
    subtitle:"Seleccioná la forma de como querés que te contactemos para coordinar día y horario para la instalación del equipo Frío-Calor.",
    description:"Horarios de atención:  lunes a viernes de 8 a 18 hs. y sábados de 8 a 15 hs.",
    options:[
        {
            label:"Whatsapp",
            img:"/wpp-icon.svg",
            name:"method_contact",
            value:"whatsapp",
        },
        {
            label:"Teléfono",
            img:"/phone-icon.svg",
            name:"method_contact",
            value:"teléfono",
        },
    ],
    addDispenser:{
        icon:"/heart-icon.svg",
        title: "Queremos darte lo mejor 🤗",
        badge: "Gratis y con envío en 24h",
        subtitle: <p className="text-center text-md-start mt-3 mt-lg-2 mb-3 text-dark-grey text-regular">Mientras coordinamos día para la instalación del equipo Frío-Calor ofrecemos entregarte <span className="text-cd-primary text-medium">un dispenser natural + botellón ¡GRATIS Y EN 24 HORAS!</span></p>,
        checkLabel: "Me gustaría recibir un botellón + dispenser natural gratis y en 24 horas",
    }
}
