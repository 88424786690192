import * as React from "react";
import {stepProductsData} from "../../data/steps/products";
import ProductRadiobutton from "../Buttons/ProductRadiobutton";
import ProductCard from "../Cards/ProductCard";
import {OrderContext} from '../../context/OrderContext';
import {Form} from 'react-bootstrap'
import FooterButtonContainer from "../Buttons/FooterButtonContainer";


const Products = ({stepBackwards, stepForward, currentStep}) => {
  
  const {products, step, setStep, formValues} = React.useContext(OrderContext) 
  const productsOffers = products.filter((prod) => prod.category_id === 1)
  const productsOthers = products.filter((prod) => prod.category_id === 2)
  const {title, products_type} = stepProductsData;
  const [validated, setValidated] = React.useState(false);

  /* ---------------------- Handler para avanzar de form ---------------------- */



  const handleNextForm = (event) => {
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
        stepForward()
        setStep(step + 1)
    }
    setValidated(true);    
  }
  
  return (
    <Form className="step-products" noValidate validated={validated} onSubmit={handleNextForm}>
      <h1 className="heading-3 mb-4">{title}</h1>
        { formValues.product_type !== 3 ?
          products_type.map((product_type,i) => (
            <div className="form-container-products mb-4" key={i}>
              <h2 className="fs-20 text-medium mb-4">{product_type.title}</h2>
              <div className="row">
                {
                  product_type.type === "radiobutton" ?
                  productsOffers.map((product, i) => (
                    <div className="col-12 col-md-4" key={i}>
                      <ProductRadiobutton
                          product={product}
                      />
                    </div>
                  )) :
                  productsOthers.map((product, i) => (
                    <div className="col-12 col-md-4" key={i}>
                      <ProductCard
                        product={product}
                        showQuantitySelector={true}
                      />
                    </div>
                  )) 
                }
              </div>
            </div>
          )) : 
            
            <div className="form-container-products mb-4" >
              <h2 className="fs-20 text-medium mb-4">Otros productos</h2>
              <div className="row">
                {
                  productsOthers.map((product, i) => (
                    <div className="col-12 col-md-4" key={i}>
                      <ProductCard
                        product={product}
                        showQuantitySelector={true}
                      />
                    </div>
                  )) 
                }
              </div>
            </div>
          

        }
      <FooterButtonContainer 
        stepBackwards={stepBackwards} 
        stepForward={stepForward} 
        currentStep={currentStep}
      />
    </Form>
  )
}

export default Products;