import React from "react";
import {stepDispenserData} from "../../data/steps/dispenser";
import DispenserRadiobutton from "../Buttons/DispenserRadiobutton";
import MainDispenserRadiobutton from "../Buttons/MainDispenserRadiobutton";
import { OrderContext } from "../../context/OrderContext";
import FooterButtonContainer from "../Buttons/FooterButtonContainer";
import {Form} from "react-bootstrap";




const Dispenser = ({stepBackwards, stepForward, currentStep}) => {


  const {categories, setFormValues, formValues, step, setStep} = React.useContext(OrderContext)
  const {title} = stepDispenserData;
  const [validated, setValidated] = React.useState(false);


  /* ---------------------- Handler para avanzar de form ---------------------- */
  const handleNextForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      stepForward()
      setStep(step + 1)
    }
    event.preventDefault(); setValidated(true);
  }


  return (
    <div className="step-dispenser">
      <h1 className="heading-3 mb-4">{title}</h1>
      <Form className="form-container" noValidate validated={validated} onSubmit={handleNextForm}>
        <div className="row"> 
          {
            categories.map((category,i) => (
              <div className={`col-12 col-md-${category.id !== 3 ? "6" : "12"} mb-3`} key={category.id}>
                {category.id !== 3 ?
                  <MainDispenserRadiobutton
                    id={category.id}
                    name={"category_id"}
                    title={category.name}
                    subtitle={`${category.description}`}
                    img={category.image}
                    measures={category.sizes}
                    setFormValues={setFormValues}
                    formValues={formValues}
                  /> :
                  <DispenserRadiobutton 
                    id={category.id}
                    name={"category_id"}
                    title={category.name}
                    subtitle={category.description}
                    img={category.image}
                    setFormValues={setFormValues}
                    formValues={formValues}
                  />
                }
              </div>
            ))
          }
        </div>
        <FooterButtonContainer 
          stepBackwards={stepBackwards} 
          stepForward={stepForward} 
          currentStep={currentStep}
        />
      </Form>
    </div>
  )
}

export default Dispenser;