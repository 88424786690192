import React from "react";
import { OrderContext } from "../../context/OrderContext";
import {Form} from 'react-bootstrap'
const ProductRadiobutton = ({product}) => {

    const { products, setProducts } = React.useContext(OrderContext);
    const addToCart = () => {
        const productsOffers = products.filter((prod) => prod.category_id !== 3)
        if(productsOffers.some((prod) => prod.quantity !== 0)) {
           const prodO = products.find((prod) => prod.category_id !== 3 && prod.quantity !== 0)   
           const newProducts = products.map((prod) =>  prod.id === prodO.id ? {...prod, quantity: 0} : prod)
           const updatedProducts = newProducts.map(((prod) => prod.id === product.id ? {...prod, quantity: prod.quantity + 1 } : prod))
           setProducts(updatedProducts)

        }
        else {
            setProducts(products.map((prod) => prod.id === product.id ? {...prod, quantity: product.quantity + 1} : prod))
        }
    }
    return (
        <div className="product-radiobutton my-2">
            <label htmlFor={product.id} className="d-flex flex-column text-center align-items-center justify-content-center position-relative">
                <Form.Check 
                    id={product.id}
                    value=""
                    required
                    name={product.category_id}
                    type="radio"
                    className="radiobutton" 
                    onClick={addToCart}
                    defaultChecked={products.find((prod) => prod.id === product.id && prod.quantity !== 0) ? true : false}
                />
                <img src={`http://api-landing.rosmino.com.ar/${product.image}`} height="120" alt={`Rosmino Ivess - Dispenser ${product.name}`} />
                <h3 className="fs-14 text-dark-grey text-center text-regular mb-2 mt-3">{product.name}</h3>
                <p className="text-black text-center">$ {product.price}</p>
            </label>
        </div>
    )
}

export default ProductRadiobutton;