import React from 'react';



export const stepDispenserData = {
    title:"Elegí tu dispenser",
    radiobuttons:[
        {
            id:"natural",
            name:"dispenser",
            title:"Natural",
            subtitle:<p className="fs-12 text-grey text-regular">Obtené <span className="text-bold">sin cargo</span> el dispenser (entregado en comodato) y empezá a disfrutar agua de calidad a temperatura ambiente.</p>,
            img:"/dispenser-natural.png",
            measures:{
                height:"80cm",
                width:"40cm",
                depth:"40cm"
            },
            columns:6,
            type:"main",
        },
        {
            id:"hot-cold",
            name:"dispenser",
            title:"Frío-Calor",
            subtitle:<p className="fs-12 text-grey text-regular">Obtené <span className="text-bold">sin cargo</span> el dispenser (entregado en comodato) con la <span className="text-bold">comprá mínima de un botellón por semana</span> y elegí la temperatura que necesites.<br></br>¡Mantenimiento y servicio ténico sin cargo!</p>,
            img:"/dispenser-hot-cold.png",
            measures:{
                height:"130cm",
                width:"40cm",
                depth:"40cm"
            },
            columns:6,
            type:"main",
        },
        {
            id:"no-dispenser",
            name:"dispenser",
            title:"Me interesan otros productos",
            subtitle:<p className="fs-12 text-grey text-regular">No necesito de un dispenser, quiero otros productos.</p>,
            img:"/ivess-products.png",
            columns:12,
            type:"secondary"
        }
    ]
}