import React, {useState} from "react";
import {stepVisitContactData, stepVisitData} from "../../data/steps/visit";
import Radiobutton from "../Buttons/Radiobutton";
import {OrderContext} from "../../context/OrderContext";
import moment from "moment"
import axios from "axios"
import "moment/locale/es";
import {Form} from 'react-bootstrap'
import FooterButtonContainer from "../Buttons/FooterButtonContainer";




const Visit = ({stepBackwards, stepForward, currentStep}) => {

  const {formValues, route, handleFormChange, setIsEarlyVisit, step, setStep, recive_drum, setReciveDrum} = React.useContext(OrderContext);

  const [today, setToday] = useState();
  const [showEarlierDates, setShowEarlierDates] = useState(false);

  React.useEffect(() => {
    if ((formValues.date_visit === moment(new Date()).format("YYYY-MM-DD")) && recive_drum){
      setShowEarlierDates(true);
    } else if ((formValues.date_visit === moment(new Date()).add(1, "days").format("YYYY-MM-DD")) && recive_drum){
      setShowEarlierDates(true);
    }else{
      setShowEarlierDates(false);
    }
  },[]);

  React.useEffect(() => {
    axios.get(`https://api-landing.rosmino.com.ar/wcp/route-status?route=${route.route_number}`).then((res) => {
    if(res.data.status === "success") {
      setToday(true)
    }
    if(res.data.status === "not_available") {
      setToday(false)
    }
    if(res.data.status === "server_not_available") {
      setToday(false)

    } 
    }).catch((err) => console.error(err))
  },[]);

  const [validated, setValidated] = React.useState(false);

  /* ---------------------- Handler para avanzar de form ---------------------- */
  const handleNextForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      stepForward()
      setStep(step + 1)
    }
    event.preventDefault(); setValidated(true);
  }


  return (
    <Form className="step-visit" noValidate validated={validated} onSubmit={handleNextForm}>
        { formValues.product_type === 2 ?
            <div>
              <h1 className="heading-3 mb-4">{stepVisitContactData.title}</h1>  
                <div className="form-container">
                  <h2 className="fs-20 text-dark-grey">{stepVisitContactData.subtitle}</h2>
                  <div className="d-flex align-items-center mt-3 mb-4">
                    <img src="/headphones.svg" alt="icon" />
                    <p className="text-regular text-grey ms-3">{stepVisitContactData.description}</p>
                  </div>
                  {
                    stepVisitContactData.options?.map((option,i) => (
                      <Radiobutton
                        key={i}
                        name={option.name}
                        id={option.value}
                        value={option.value}
                        label={option.label}
                        img={option.img}
                        handleFormChange={handleFormChange}
                        formValues={formValues}
                        required={true}

                      />
                    ))
                  }
                  <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start my-5">
                    <div className="d-flex me-3">
                      <div className="icon-container">
                        <img src={stepVisitContactData.addDispenser.icon} alt="icon" />
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <h3 className="fs-16 text-bold my-3 my-lg-0">{stepVisitContactData.addDispenser.title}</h3>
                        <span className="badge">{stepVisitContactData.addDispenser.badge}</span>
                      </div>
                      {stepVisitContactData.addDispenser.subtitle}
                      <label htmlFor="add-dispenser" className="text-dark-grey text-regular">
                        <input 
                          id="add-dispenser" 
                          type="checkbox"
                          name="recive_drum"
                          onChange={(e) => setReciveDrum(e.target.checked)}
                          className="me-2"
                          defaultChecked={recive_drum}
                        />
                        {stepVisitContactData.addDispenser.checkLabel}
                      </label>
                    </div>
                  </div>
                  {
                    recive_drum &&
                    <div className="mb-3">
                      <h3 className="fs-20 text-medium mb-3">¿Cuándo querés que te visitemos?</h3>
                        <Radiobutton 
                          name="date_visit" 
                          handleFormChange={e => { handleFormChange(e); setIsEarlyVisit(false) }} 
                          label={`${moment(route.next_delivery).format("DD")} de ${moment(route.next_delivery).format("MMMM")}`} 
                          description={`de ${route.visit_between_initial} a ${route.visit_between_final}`} 
                          value={route.next_delivery}
                          formValues={formValues}
                          required={true}
                        />
                        {
                          !showEarlierDates ?
                          <button 
                            className="d-flex align-items-center mt-4"
                            onClick={() => setShowEarlierDates(!showEarlierDates)}
                          >
                            <img src="/rayo.svg" alt="icon" />
                            <p class="text-cd-primary text-regular">Necesito recibir el pedido más rápido</p>
                          </button> :
                          <>
                            {today && <Radiobutton 
                              name="date_visit" 
                              handleFormChange={e => { handleFormChange(e); setIsEarlyVisit(true) }} 
                              label={`Hoy`} 
                              // description={`de ${route.visit_between_initial} a ${route.visit_between_final}`} 
                              value={route.todays_date}
                              formValues={formValues}
                              required={true}
                            />}
                            <Radiobutton 
                              name="date_visit" 
                              handleFormChange={e => { handleFormChange(e); setIsEarlyVisit(true) }} 
                              label={`Mañana`} 
                              // description={`de ${route.visit_between_initial} a ${route.visit_between_final}`} 
                              value={route.tomorrows_date} 
                              formValues={formValues}
                              required={true}
                            />
                          </>
                        }
                    </div>
                  }
              </div>
            </div>
          :
          <div className='form-container'>
            <h1 className="heading-3 mb-4">{stepVisitData.title}</h1>
            <Radiobutton 
              name="date_visit" 
              handleFormChange={e => { handleFormChange(e); setIsEarlyVisit(false)}}
              label={`${moment(route.next_delivery).format("DD")} de ${moment(route.next_delivery).format("MMMM")}`} 
              description={`de ${route.visit_between_initial} a ${route.visit_between_final}`} 
              value={route.next_delivery}
              formValues={formValues}
              required={true}
            />
            {
              !showEarlierDates ?
              <button 
                className="d-flex align-items-center mt-4"
                onClick={() => setShowEarlierDates(!showEarlierDates)}
              >
                <img src="/rayo.svg" className="early-dates-icon" alt="icon" />
                <p class="text-cd-primary text-regular">Necesito recibir el pedido más rápido</p>
              </button> :
              <>
                {today && <Radiobutton 
                  name="date_visit" 
                  handleFormChange={e => { handleFormChange(e); setIsEarlyVisit(true)}} 
                  label={`Hoy`} 
                  // description={`de ${route.visit_between_initial} a ${route.visit_between_final}`} 
                  value={route.todays_date}
                  formValues={formValues}
                  required={true}
                />}
                <Radiobutton 
                  name="date_visit" 
                  handleFormChange={e => { handleFormChange(e); setIsEarlyVisit(true)}} 
                  label={`Mañana`} 
                  // description={`de ${route.visit_between_initial} a ${route.visit_between_final}`} 
                  value={route.tomorrows_date} 
                  formValues={formValues}
                  required={true}
                />
              </>  
            }
          </div>      
        }  
      <FooterButtonContainer 
        stepBackwards={stepBackwards} 
        stepForward={stepForward} 
        currentStep={currentStep}
      />
    </Form>
  )
}

export default Visit;