import * as React from "react";
import {Form} from 'react-bootstrap'

const DispenserRadiobutton = (props) => {

    const {title, subtitle, name, id, img, setFormValues, formValues} = props;

    return (
        <div className="dispenser-radiobutton">
            <label htmlFor={id} className="w-100">
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                        <h2 className="fs-16 text-bold">{title}</h2>
                        <p className="fs-12 text-dark-grey mb-3 text-regular">{subtitle}</p>
                    </div>
                    <div className="d-flex">
                        <img src={`http://api-landing.rosmino.com.ar/${img}`} width="174" alt={`Rosmino Ivess - Dispenser ${title}`} />
                        <Form.Check 
                            id={id}
                            value=""
                            name={name}
                            type="radio" 
                            className="radiobutton"
                            required
                            onChange={() => setFormValues({product_type: id})}
                            defaultChecked={formValues["product_type"] === id ? true : false}
                        />
                    </div>
                </div>
            </label>
        </div>
    )
}

export default DispenserRadiobutton;