

export const stepProductsData = {
    title:"¿Qué estás buscando?",
    products_type:[
        {
            title:"Promoción para nuevos clientes",
            name:"client-offers",
            type:"radiobutton"
        },
        {
            title:"Otros productos",
            type:"card"
        },
    ]
}